import { httpRequestHeaders, PATIENT_API_URL } from 'utils/helpers/patientApi';

interface KitActivationSuccessResponse {
  deviceExpirationDate: string;
  deviceType?: string;
  collectionComplete: boolean;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  activationCode: string;
  docType?: string;
}

export const submitActivationCode = async (
  activationCode: string,
  token: string | undefined,
  errorText: string | undefined
): Promise<KitActivationSuccessResponse | string> => {
  const requestHeaders = httpRequestHeaders({ recaptcha: token });

  return fetch(`${PATIENT_API_URL}/activations/${activationCode}`, {
    method: 'GET',
    headers: requestHeaders,
  })
    .then(async (response: Response) => {
      if (response.status === 200) {
        const responseBody = await response.json();
        return responseBody as KitActivationSuccessResponse;
      } else if (response.status === 400 || response.status === 404) {
        return errorText || 'Invalid registration. Please try again.';
      } else {
        return 'Something went wrong. Please try again later.';
      }
    })
    .catch((e) => {
      console.log('Fetch Error:', e);
      return 'Something went wrong. Please try again later.';
    });
};
